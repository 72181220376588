import {Languages} from 'shared/constants/AppEnums';
import {CategoryModel, ContentModel} from 'types/models/Common';
import {NewsFeedData, NewsFeedModel} from 'types/models/NewsFeed';

export const GET_NEWS_FEED_DATA = 'GET_NEWS_FEED_DATA';
export const ADD_NEWS_FEED_DATA = 'ADD_NEWS_FEED_DATA';
export const EDIT_NEWS_FEED_INFORMATION_START =
  'EDIT_NEWS_FEED_INFORMATION_START';
export const EDIT_NEWS_FEED_INFORMATION_FINISH =
  'EDIT_NEWS_FEED_INFORMATION_FINISH';
export const SET_NEWS_FEED_TABLE_PAGE = 'SET_NEWS_FEED_TABLE_PAGE';
export const SET_NEWS_FEED_ROWS_PER_PAGE = 'SET_NEWS_FEED_ROWS_PER_PAGE';
export const OPEN_NEWS_FEED_DELETE_DIALOG = 'OPEN_NEWS_FEED_DELETE_DIALOG';
export const CLOSE_NEWS_FEED_DELETE_DIALOG = 'CLOSE_NEWS_FEED_DELETE_DIALOG';
export const GET_CATEGORIES = 'GET_CATEGORIES';

export interface GetCategories {
  type: typeof GET_CATEGORIES;
  payload: CategoryModel[];
}

export interface GetNewsFeedData {
  type: typeof GET_NEWS_FEED_DATA;
  payload: NewsFeedData;
  language: Languages;
}
export interface AddNewsFeedData {
  type: typeof ADD_NEWS_FEED_DATA;
  payload: NewsFeedModel[];
  language: Languages;
}
export interface EditNewsFeedInformationStart {
  type: typeof EDIT_NEWS_FEED_INFORMATION_START;
  payload: NewsFeedModel;
  language: Languages;
}

export interface EditNewsFeedInformationFinish {
  type: typeof EDIT_NEWS_FEED_INFORMATION_FINISH;
  language: Languages;
}

export type SetNewsFeedTablePage = {
  type: typeof SET_NEWS_FEED_TABLE_PAGE;
  page: number;
  language: Languages;
};

export type SetNewsFeedRowsPerPage = {
  type: typeof SET_NEWS_FEED_ROWS_PER_PAGE;
  rowsPerPage: number;
  language: Languages;
};

export type OpenNewsFeedDeleteDialog = {
  type: typeof OPEN_NEWS_FEED_DELETE_DIALOG;
  payload: ContentModel;
  language: Languages;
};

export type CloseNewsFeedDeleteDialog = {
  type: typeof CLOSE_NEWS_FEED_DELETE_DIALOG;
  language: Languages;
};

export type NewsFeedActionTypes =
  | GetNewsFeedData
  | AddNewsFeedData
  | OpenNewsFeedDeleteDialog
  | CloseNewsFeedDeleteDialog
  | SetNewsFeedTablePage
  | SetNewsFeedRowsPerPage
  | EditNewsFeedInformationFinish
  | EditNewsFeedInformationStart
  | GetCategories;
