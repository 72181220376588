import React from 'react';

export const dashboardRouteConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'sidebar.app.dashboard',
    type: 'item',
    icon: 'dashboard',
  },
];

export const dashBoardConfigs = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/dashboard/analytics',
        component: React.lazy(() => import('./Analytics')),
      },
    ],
  },
];
