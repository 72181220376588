import {useMediaQuery, useTheme} from '@material-ui/core';

export const useBreakPointDown = (key: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(key));
};

export const createRoutes = (routeConfigs: any[]) => {
  let allRoutes: any[] = [];
  routeConfigs.forEach((config) => {
    allRoutes = [...allRoutes, ...setRoutes(config)];
  });
  return allRoutes;
};

export const setRoutes = (config: any) => {
  let routes = [...config.routes];
  if (config.auth) {
    routes = routes.map((route) => {
      let auth = route.auth
        ? [...config.auth, ...route.auth]
        : [...config.auth];
      return {...route, auth};
    });
  }

  return [...routes];
};
export const getBreakPointsValue = (valueSet: any, breakpoint: string) => {
  if (typeof valueSet === 'number') return valueSet;
  switch (breakpoint) {
    case 'xs':
      return valueSet.xs;
    case 'sm':
      return valueSet.sm || valueSet.xs;
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs;
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};

export const authHeader = (): object => {
  const data = localStorage.getItem('userInfo');
  const user = data ? JSON.parse(data) : null;
  if (user && user.token) {
    return {Authorization: 'Bearer ' + user.token};
  } else {
    return {};
  }
};

export const isAuthTokenExpired = (jwtToken?: string): boolean | null => {
  if (!jwtToken) {
    return null;
  }

  const jwt = JSON.parse(atob(jwtToken.split('.')[1]));

  // multiply by 1000 to convert seconds into milliseconds
  const exp: number | null = (jwt && jwt.exp && jwt.exp * 1000) || null;

  if (!exp) {
    return false;
  }

  return Date.now() > exp;
};

export const sortById = (
  arr: {id: number; [key: string]: any}[],
): {id: number; [key: string]: any}[] => {
  arr = arr.sort((a, b) => a.id - b.id);
  return arr;
};

// export const determineLastPage = (
//   page: number,
//   prevPage: number | undefined,
//   rowsPerPage: number,
// ): number => {
//   if (prevPage === undefined) return page;
//   else if (((page + 1) * rowsPerPage) % 20 === 0) {
//     const reqPage = ((page + 1) * rowsPerPage) / 20 + 1;
//     if (reqPage > prevPage) return reqPage;
//   }
//   return prevPage;
// };

// export const determineLastPage = (
//  entries:number
// ): number => {

//     return
// };

export const removeElementsFromBack = (arr: any[], n: number) => {
  arr.splice(-1, n);
  return arr;
};

export const determinePage = (
  prevVal: number,
  newVal: number,
  page: number,
): number => {
  page++;
  // need to handle -1
  if (newVal === -1) newVal = 20;

  if (prevVal > newVal) {
    const multiplier = prevVal / newVal;
    return page * Math.floor(multiplier) - multiplier;
  } else if (prevVal < newVal) {
    const divider = newVal / prevVal;
    // alert(prevVal);

    // if (prevVal === 10) {
    //   let val = page / Math.floor(divider) - 1 + divider;
    //   if (val % 2 === 0) return val - 1;
    //   else return val;
    //   // return even
    // }

    return Math.floor(page / Math.floor(divider));
  }
  return page - 1;
};
