import {fetchError, fetchStart, fetchSuccess} from '../Common';
import {AppActions} from '../../../types';
import {Dispatch} from 'redux';
import ApiService from '@crema/services/ApiService';
import {
  ADD_PHC_TABLE_PARAMS,
  CenterActionTypes,
  CLOSE_PHC_DELETE_DIALOG,
  EDIT_PHC_INFORMATION_FINISH,
  EDIT_PHC_INFORMATION_START,
  GET_PHC_DATA,
  OPEN_PHC_DELETE_DIALOG,
} from 'types/actions/Center.action';
import {PHCModel} from 'types/models/Center';
import {phcApi} from 'shared/constants/AppConst';
import {PaginationData} from 'types/models/Common';

/**
 * Content Actions
 */

export const onGetPHCData = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(phcApi.getAll);

      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,
        pagination: {
          start: 0,
          limit: 10,
          page: 0,
          count: data.total, //total
          tableParams: {},

          // id, name ,contact number
        },
      };

      dispatch({
        type: GET_PHC_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onEditPHCInformationStart = (content: PHCModel) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch({
        type: EDIT_PHC_INFORMATION_START,
        payload: content,
      });

      return content;
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onEditPHCInformationFinish = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: EDIT_PHC_INFORMATION_FINISH,
    });
  };
};

export const openPHCDeleteDialog = (payload: PHCModel) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: OPEN_PHC_DELETE_DIALOG,
      payload,
    });
  };
};

export const closePHCDeleteDialog = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: CLOSE_PHC_DELETE_DIALOG,
    });
  };
};

export const setPHCCurrentTablePage = (
  page: number,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {limit, tableParams} = paginationData;
      const {sortBy, order, filters} = tableParams;
      const {id, location} = filters || {};
      let queryString = '';

      if (id) queryString = `&id=${id}`;
      if (location) queryString = `&location=${location}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      const start = limit * page;
      let data = await ApiService.get(phcApi.get(start, limit, queryString));

      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,
        pagination: {
          start,
          page,
          limit,
          count: data.total, //total
          tableParams,
        },
      };

      dispatch({
        type: GET_PHC_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const setPHCRowsPerPage = (
  limit: number,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {tableParams} = paginationData;
      const {sortBy, order, filters} = tableParams;
      let queryString = '';

      const {id, phc, location} = filters || {};

      if (id) queryString = `&id=${id}`;
      if (phc) queryString += `&phc=${phc}`;
      if (location) queryString += `&location=${location}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      let data = await ApiService.get(phcApi.get(0, limit, queryString));

      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,
        pagination: {
          start: 0,
          page: 0,
          limit,
          count: data.total,
          tableParams,
        },
      };

      dispatch({
        type: GET_PHC_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const addPHCTableParams = (tableParams: any): CenterActionTypes => ({
  type: ADD_PHC_TABLE_PARAMS,
  payload: tableParams,
});

export const setPHCSorting = (
  pagination: PaginationData['pagination'],
  sortBy: string,
  order: string,
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const tableParams = {
        ...pagination.tableParams,
        sortBy,
        order,
      };

      dispatch(addPHCTableParams(tableParams));

      let data = await ApiService.get(
        phcApi.get(
          pagination.start,
          pagination.limit,
          `&_sortBy=${sortBy}&_order=${order}`,
        ),
      );

      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,
        pagination: {
          ...pagination,
          tableParams: {sortBy, order},
          count: data.total, //total
        },
      };

      dispatch({
        type: GET_PHC_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onFilterPHC = (
  values: any,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {limit, tableParams} = paginationData;
      const {sortBy, order} = tableParams;
      const {id, phc, location} = values || {};

      let queryString = '';

      if (id) queryString = `&id=${id}`;
      if (phc) queryString += `&phc=${phc}`;
      if (location) queryString += `&location=${location}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      console.log(queryString);
      let data = await ApiService.get(phcApi.get(0, limit, queryString));

      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,

        pagination: {
          start: 0,
          page: 0,
          limit: limit,
          count: data.total, //total
          tableParams: {
            ...tableParams,
            filters: {
              ...values,
            },
          },
        },
      };
      dispatch({
        type: GET_PHC_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};
