import {
  CLOSE_NEWS_FEED_DELETE_DIALOG,
  EDIT_NEWS_FEED_INFORMATION_FINISH,
  EDIT_NEWS_FEED_INFORMATION_START,
  GET_CATEGORIES,
  GET_NEWS_FEED_DATA,
  NewsFeedActionTypes,
  OPEN_NEWS_FEED_DELETE_DIALOG,
} from 'types/actions/NewsFeed.action';
import {NewsFeedReducerState} from 'types/models/NewsFeed';

const initialState: NewsFeedReducerState = {
  en: {
    data: [],
    editing: false,
    pagination: {
      start: 0,
      limit: 10,
      page: 0,
      count: 0,
    },
    selectedFeed: null,
    deleting: false,
  },
  ar: {
    data: [],
    editing: false,
    pagination: {
      start: 0,
      limit: 10,
      page: 0,
      count: 0,
    },
    selectedFeed: null,
    deleting: false,
  },
  categoryData: [],
};

export default (
  state = initialState,
  action: NewsFeedActionTypes,
): NewsFeedReducerState => {
  switch (action.type) {
    case GET_NEWS_FEED_DATA:
      return {
        ...state,
        [action.language]: {
          ...state[action.language],
          ...action.payload,
        },
      };

    // case SET_NEWS_FEED_ROWS_PER_PAGE:
    //   return {
    //     ...state,
    //     [action.language]: {
    //       ...state[action.language],
    //       pagination: {
    //         ...state[action.language].pagination,
    //         rowsPerPage: action.rowsPerPage || 0,

    //         page: determinePage(
    //           state[action.language].pagination.rowsPerPage,
    //           action.rowsPerPage,
    //           state[action.language].pagination.page,
    //         ),
    //       },
    //     },
    //   };

    case OPEN_NEWS_FEED_DELETE_DIALOG:
      return {
        ...state,
        [action.language]: {
          ...state[action.language],
          deleting: true,
          selectedFeed: action.payload,
        },
      };
    case CLOSE_NEWS_FEED_DELETE_DIALOG:
      return {
        ...state,
        [action.language]: {
          ...state[action.language],
          deleting: false,
          selectedFeed: null,
        },
      };
    case EDIT_NEWS_FEED_INFORMATION_START:
      return {
        ...state,
        [action.language]: {
          ...state[action.language],
          editing: true,
          selectedFeed: action.payload,
        },
      };
    case EDIT_NEWS_FEED_INFORMATION_FINISH:
      return {
        ...state,
        [action.language]: {
          ...state[action.language],
          editing: false,
          selectedFeed: null,
        },
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categoryData: action.payload,
      };
    default:
      return state;
  }
};
