import {
  CenterData,
  CenterModel,
  PharmaciesCenterData,
  PharmaciesModel,
  PHCCenterData,
  PHCModel,
} from 'types/models/Center';

export const GET_CENTER_DATA = 'GET_CENTER_DATA';
export const ADD_CENTER_DATA = 'ADD_CENTER_DATA';
export const ADD_CENTER_TABLE_PARAMS = 'ADD_CENTER_TABLE_PARAMS';
export const EDIT_CENTER_INFORMATION_START = 'EDIT_CENTER_INFORMATION_START';
export const EDIT_CENTER_INFORMATION_FINISH = 'EDIT_CENTER_INFORMATION_FINISH';
export const SET_CENTER_TABLE_PAGE = 'SET_CENTER_TABLE_PAGE';
export const SET_CENTER_ROWS_PER_PAGE = 'SET_CENTER_ROWS_PER_PAGE';
export const OPEN_CENTER_DELETE_DIALOG = 'OPEN_CENTER_DELETE_DIALOG';
export const CLOSE_CENTER_DELETE_DIALOG = 'CLOSE_CENTER_DELETE_DIALOG';

export interface GetCenterData {
  type: typeof GET_CENTER_DATA;
  payload: CenterData;
}

export interface AddCenterTableParams {
  type: typeof ADD_CENTER_TABLE_PARAMS;
  payload: CenterData;
}

export interface EditCenterInformationStart {
  type: typeof EDIT_CENTER_INFORMATION_START;
  payload: CenterModel;
}

export interface EditCenterInformationFinish {
  type: typeof EDIT_CENTER_INFORMATION_FINISH;
}

export type SetCenterTablePage = {
  type: typeof SET_CENTER_TABLE_PAGE;
  page: number;
};

export type SetCenterRowsPerPage = {
  type: typeof SET_CENTER_ROWS_PER_PAGE;
  rowsPerPage: number;
};

export type OpenCenterDeleteDialog = {
  type: typeof OPEN_CENTER_DELETE_DIALOG;
  payload: CenterModel;
};

export type CloseCenterDeleteDialog = {
  type: typeof CLOSE_CENTER_DELETE_DIALOG;
};

/**
 * PHC
 */
export const GET_PHC_DATA = 'GET_PHC_DATA';
export const ADD_PHC_TABLE_PARAMS = 'ADD_PHC_TABLE_PARAMS';
export const REMOVE_PHC_DATA = 'REMOVE_PHC_DATA';
export const EDIT_PHC_INFORMATION_START = 'EDIT_PHC_INFORMATION_START';
export const EDIT_PHC_INFORMATION_FINISH = 'EDIT_PHC_INFORMATION_FINISH';
export const SET_PHC_TABLE_PAGE = 'SET_PHC_TABLE_PAGE';
export const SET_PHC_ROWS_PER_PAGE = 'SET_PHC_ROWS_PER_PAGE';
export const OPEN_PHC_DELETE_DIALOG = 'OPEN_PHC_DELETE_DIALOG';
export const CLOSE_PHC_DELETE_DIALOG = 'CLOSE_PHC_DELETE_DIALOG';

export interface GetPHCData {
  type: typeof GET_PHC_DATA;
  payload: PHCCenterData;
}

export interface AddPHCTableParams {
  type: typeof ADD_PHC_TABLE_PARAMS;
  payload: PHCCenterData;
}

export interface RemovePHCData {
  type: typeof REMOVE_PHC_DATA;
}

export interface EditPHCInformationStart {
  type: typeof EDIT_PHC_INFORMATION_START;
  payload: PHCModel;
}

export interface EditPHCInformationFinish {
  type: typeof EDIT_PHC_INFORMATION_FINISH;
}

export type SetPHCTablePage = {
  type: typeof SET_PHC_TABLE_PAGE;
  page: number;
};

export type SetPHCRowsPerPage = {
  type: typeof SET_PHC_ROWS_PER_PAGE;
  rowsPerPage: number;
};

export type OpenPHCDeleteDialog = {
  type: typeof OPEN_PHC_DELETE_DIALOG;
  payload: PHCModel;
};

export type ClosePHCDeleteDialog = {
  type: typeof CLOSE_PHC_DELETE_DIALOG;
};

/**
 * Pharmacies
 */
export const GET_PHARMACIES_DATA = 'GET_PHARMACIES_DATA';
export const ADD_PHARMACIES_TABLE_PARAMS = 'ADD_PHARMACIES_TABLE_PARAMS';
export const EDIT_PHARMACIES_INFORMATION_START =
  'EDIT_PHARMACIES_INFORMATION_START';
export const EDIT_PHARMACIES_INFORMATION_FINISH =
  'EDIT_PHARMACIES_INFORMATION_FINISH';
export const SET_PHARMACIES_TABLE_PAGE = 'SET_PHARMACIES_TABLE_PAGE';
export const SET_PHARMACIES_ROWS_PER_PAGE = 'SET_PHARMACIES_ROWS_PER_PAGE';
export const OPEN_PHARMACIES_DELETE_DIALOG = 'OPEN_PHARMACIES_DELETE_DIALOG';
export const CLOSE_PHARMACIES_DELETE_DIALOG = 'CLOSE_PHARMACIES_DELETE_DIALOG';

export interface GetPharmaciesData {
  type: typeof GET_PHARMACIES_DATA;
  payload: PharmaciesCenterData;
}

export interface AddPharmaciesTableParams {
  type: typeof ADD_PHARMACIES_TABLE_PARAMS;
  payload: PharmaciesCenterData;
}

export interface EditPharmaciesInformationStart {
  type: typeof EDIT_PHARMACIES_INFORMATION_START;
  payload: PharmaciesModel;
}

export interface EditPharmaciesInformationFinish {
  type: typeof EDIT_PHARMACIES_INFORMATION_FINISH;
}

export type SetPharmaciesTablePage = {
  type: typeof SET_PHARMACIES_TABLE_PAGE;
  page: number;
};

export type SetPharmaciesRowsPerPage = {
  type: typeof SET_PHARMACIES_ROWS_PER_PAGE;
  rowsPerPage: number;
};

export type OpenPharmaciesDeleteDialog = {
  type: typeof OPEN_PHARMACIES_DELETE_DIALOG;
  payload: PharmaciesModel;
};

export type ClosePharmaciesDeleteDialog = {
  type: typeof CLOSE_PHARMACIES_DELETE_DIALOG;
};

export type CenterActionTypes =
  | GetCenterData
  | AddCenterTableParams
  | OpenCenterDeleteDialog
  | CloseCenterDeleteDialog
  | SetCenterTablePage
  | SetCenterRowsPerPage
  | EditCenterInformationFinish
  | EditCenterInformationStart
  | GetPHCData
  | AddPHCTableParams
  | RemovePHCData
  | EditPHCInformationStart
  | EditPHCInformationFinish
  | SetPHCTablePage
  | SetPHCRowsPerPage
  | OpenPHCDeleteDialog
  | ClosePHCDeleteDialog
  | GetPharmaciesData
  | AddPharmaciesTableParams
  | EditPharmaciesInformationStart
  | EditPharmaciesInformationFinish
  | SetPharmaciesTablePage
  | SetPharmaciesRowsPerPage
  | OpenPharmaciesDeleteDialog
  | ClosePharmaciesDeleteDialog;
