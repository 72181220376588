import React from 'react';

export const centerRouteConfig = [
  {
    id: 'center',
    title: 'Center',
    messageId: 'sidebar.center',
    type: 'collapse',
    icon: 'edit',
    children: [
      {
        id: 'center.center',
        title: 'Blood Donation',
        messageId: 'sidebar.center.center',
        type: 'item',
        url: '/manage/center',
      },
      {
        id: 'center.phc',
        title: 'PHC',
        messageId: 'sidebar.center.phc',
        type: 'item',
        url: '/manage/phc',
      },
      {
        id: 'center.pharmacies',
        title: 'Pharmacies',
        messageId: 'sidebar.center.pharmacies',
        type: 'item',
        url: '/manage/pharmacies',
      },
    ],
  },
];

export const centerConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/manage/center',
        component: React.lazy(() => import('./center')),
      },
      {
        path: '/manage/phc',
        component: React.lazy(() => import('./phc')),
      },
      {
        path: '/manage/pharmacies',
        component: React.lazy(() => import('./pharmacies')),
      },
    ],
  },
];
