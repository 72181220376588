import {fetchError, fetchStart, fetchSuccess} from '../Common';
import {AppActions} from '../../../types';
import {Dispatch} from 'redux';
import ApiService from '@crema/services/ApiService';
import {
  ADD_PHARMACIES_TABLE_PARAMS,
  CenterActionTypes,
  CLOSE_PHARMACIES_DELETE_DIALOG,
  EDIT_PHARMACIES_INFORMATION_FINISH,
  EDIT_PHARMACIES_INFORMATION_START,
  GET_PHARMACIES_DATA,
  OPEN_PHARMACIES_DELETE_DIALOG,
} from 'types/actions/Center.action';
import {PharmaciesModel} from 'types/models/Center';
import {pharmaciesApi} from 'shared/constants/AppConst';
import {PaginationData} from 'types/models/Common';

/**
 * Content Actions
 */

export const onGetPharmaciesData = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(pharmaciesApi.getAll);

      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,
        pagination: {
          start: 0,
          limit: 10,
          page: 0,
          count: data.total, //total
          tableParams: {},
        },
      };

      dispatch({
        type: GET_PHARMACIES_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onEditPharmaciesInformationStart = (content: PharmaciesModel) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch({
        type: EDIT_PHARMACIES_INFORMATION_START,
        payload: content,
      });

      return content;
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onEditPharmaciesInformationFinish = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: EDIT_PHARMACIES_INFORMATION_FINISH,
    });
  };
};

export const openPharmaciesDeleteDialog = (payload: PharmaciesModel) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: OPEN_PHARMACIES_DELETE_DIALOG,
      payload,
    });
  };
};

export const closePharmaciesDeleteDialog = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: CLOSE_PHARMACIES_DELETE_DIALOG,
    });
  };
};

export const setPharmaciesCurrentTablePage = (
  page: number,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {limit, tableParams} = paginationData;
      const {sortBy, order, filters} = tableParams;
      let queryString = '';

      const {id, branch_name_en, phoneNo, city_name_en} = filters || {};

      if (id) queryString = `&id=${id}`;
      if (branch_name_en) queryString += `&branch_name_en=${branch_name_en}`;
      if (city_name_en) queryString += `&city_name_en=${city_name_en}`;
      if (phoneNo) queryString += `&phoneNo=${phoneNo}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      const start = page * limit;
      let data = await ApiService.get(
        pharmaciesApi.get(start, limit, queryString),
      );

      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,
        pagination: {
          start,
          page,
          limit,
          count: data.total, //total
          tableParams,
        },
      };

      dispatch({
        type: GET_PHARMACIES_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const setPharmaciesRowsPerPage = (
  limit: number,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {tableParams} = paginationData;
      const {sortBy, order, filters} = tableParams;
      let queryString = '';

      const {id, branch_name_en, phoneNo, city_name_en} = filters || {};

      if (id) queryString = `&id=${id}`;
      if (branch_name_en) queryString += `&branch_name_en=${branch_name_en}`;
      if (city_name_en) queryString += `&city_name_en=${city_name_en}`;
      if (phoneNo) queryString += `&phoneNo=${phoneNo}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      let data = await ApiService.get(pharmaciesApi.get(0, limit, queryString));

      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,
        pagination: {
          start: 0,
          page: 0,
          limit,
          count: data.total,
          tableParams,
        },
      };

      dispatch({
        type: GET_PHARMACIES_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const addPharmaciesTableParams = (
  tableParams: any,
): CenterActionTypes => ({
  type: ADD_PHARMACIES_TABLE_PARAMS,
  payload: tableParams,
});

export const setPharmaciesSorting = (
  pagination: PaginationData['pagination'],
  sortBy: string,
  order: string,
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const tableParams = {
        ...pagination.tableParams,
        sortBy,
        order,
      };

      dispatch(addPharmaciesTableParams(tableParams));

      let data = await ApiService.get(
        pharmaciesApi.get(
          pagination.start,
          pagination.limit,
          `&_sortBy=${sortBy}&_order=${order}`,
        ),
      );

      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,
        pagination: {
          ...pagination,
          tableParams: {sortBy, order},
          count: data.total, //total
        },
      };

      dispatch({
        type: GET_PHARMACIES_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onFilterPharmacies = (
  values: any,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {limit, tableParams} = paginationData;
      const {sortBy, order} = tableParams;
      let queryString = '';

      const {id, branch_name_en, phoneNo, city_name_en} = values || {};

      if (id) queryString = `&id=${id}`;
      if (branch_name_en) queryString += `&branch_name_en=${branch_name_en}`;
      if (city_name_en) queryString += `&city_name_en=${city_name_en}`;
      if (phoneNo) queryString += `&phoneNo=${phoneNo}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      console.log(queryString);
      let data = await ApiService.get(pharmaciesApi.get(0, limit, queryString));

      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,
        pagination: {
          start: 0,
          page: 0,
          limit: limit,
          count: data.total, //total
          tableParams: {
            ...tableParams,
            filters: {
              ...values,
            },
          },
        },
      };

      queryString = '';

      dispatch({
        type: GET_PHARMACIES_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};
