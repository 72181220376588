import Settings from './Setting';
import Common from './Common';
import FirebaseAuth from './FirebaseAuth';
import Dashboard from './Dashboard';
import Management from './Management';
import NewsFeed from './NewsFeed';
import Centers from './Centers';

const reducers = {
  settings: Settings,
  auth: FirebaseAuth,
  dashboard: Dashboard,
  common: Common,
  management: Management,
  newsFeed: NewsFeed,
  centers: Centers,
};

export default reducers;
