export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  photoURL: require('assets/images/avatar/A11.jpg'),
};
export const initialUrl = '/dashboard/analytics';
export const baseApiUrl = 'https://wasfaty.antillia.io';

export const adminApiUrl = {
  get: (start: number | null, limit?: number, qString?: string): string =>
    `/admin?_start=${start}&_limit=${limit}${qString}`,
  getAll: '/admin',
  getById: (id: number | null): string => `/admin/${id}`,
  create: '/admin',
  edit: (id: number | null): string => `/admin/${id}`,
  delete: (id: number | null): string => `/admin/${id}`,
  activate: (id: number | null): string => `/admin/activate/${id}`,
  deactivate: (id: number | null): string => `/admin/deactivate/${id}`,
};

export const patientApi = {
  get: (start: number | null, limit?: number, qString?: string): string =>
    `/patient?_start=${start}&_limit=${limit}${qString}`,
  getAll: '/patient',
  getById: (id: number | null): string => `/patient/${id}`,
  create: '/patient',
  createNotification: '/patient/notification',
  edit: (id: number | null): string => `/patient/${id}`,
  delete: (id: number | null): string => `/patient/${id}`,
};

export const contentApi = {
  get: (start: number | null, limit?: number, qString?: string): string =>
    `/content?_start=${start}&_limit=${limit}${qString}`,
  getAll: '/content',
  getById: (id: number | null): string => `/content/${id}`,
  create: '/content',
  edit: (id: number | null): string => `/content/${id}`,
  delete: (id: number | null): string => `/content/${id}`,
};

export const pharmaciesApi = {
  get: (start: number | null, limit?: number, qString?: string): string =>
    `/pharmacies?_start=${start}&_limit=${limit}${qString}`,
  getAll: '/pharmacies',
  getById: (id: number | null): string => `/pharmacies/${id}`,
  edit: (id: number | null): string => `/pharmacies/${id}`,
  delete: (id: number | null): string => `/pharmacies/${id}`,
};

export const phcApi = {
  get: (start: number | null, limit?: number, qString?: string): string =>
    `/phc?_start=${start}&_limit=${limit}${qString}`,
  getAll: '/phc',
  getById: (id: number | null): string => `/phc/${id}`,
  edit: (id: number | null): string => `/phc/${id}`,
  delete: (id: number | null): string => `/phc/${id}`,
};

export const newsFeedApi = {
  get: (start: number | null, limit?: number, lang?: string): string =>
    `/newsFeed?language=${lang}&_start=${start}&_limit=${limit}`,
  getAll: (lang: string): string => `/newsFeed?language=${lang}`,
  getById: (id: number | null): string => `/newsFeed/${id}`,
  create: '/newsFeed',
  edit: (id: number | null): string => `/newsFeed/${id}`,
  delete: (id: number | null): string => `/newsFeed/${id}`,
};

export const notificationApi = {
  get: (start: number | null, limit?: number, qString?: string): string =>
    `/notifications?_start=${start}&_limit=${limit}${qString}`,
  getAll: '/notifications',
  create: (recipientType: string | null): string => {
    return recipientType === 'arrayOfId'
      ? '/notifications/by-id'
      : `/notifications/${recipientType}`;
  },
  createById: 'notifications/by-id',
};

export const centerApi = {
  getAll: '/center',
  get: (start: number | null, limit?: number, qString?: string): string =>
    `/center?_start=${start}&_limit=${limit}${qString}`,
};
