import React from 'react';

export const managementRouteConfig = [
  {
    id: 'management',
    title: 'Management',
    messageId: 'sidebar.management',
    type: 'collapse',
    icon: 'edit',
    children: [
      {
        id: 'management.admin',
        title: 'Admin',
        messageId: 'sidebar.management.admin',
        type: 'item',
        url: '/manage/admin',
      },
      {
        id: 'management.patient',
        title: 'Patient',
        messageId: 'sidebar.management.patient',
        type: 'item',
        url: '/manage/patient',
      },
      {
        id: 'management.content',
        title: 'Content',
        messageId: 'sidebar.management.content',
        type: 'item',
        url: '/manage/content',
      },
      {
        id: 'management.notification',
        title: 'Notification',
        messageId: 'sidebar.management.notification',
        type: 'item',
        url: '/manage/notification',
      },
    ],
  },
];

export const managementConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/manage/admin',
        component: React.lazy(() => import('./Admin')),
      },
      {
        path: '/manage/patient',
        component: React.lazy(() => import('./Patient')),
      },
      {
        path: '/manage/content',
        component: React.lazy(() => import('./content')),
      },
      {
        path: '/manage/notification',
        component: React.lazy(() => import('./Notification')),
      },
    ],
  },
];
