import {managementRouteConfig} from './management';
import {dashboardRouteConfig} from './dashboard';
// import {newsFeedRouteConfig} from './newsFeed';
// import {reportsRouteConfig} from './reports';
// import {errorPagesRouteConfig} from './errorPages';
import {centerRouteConfig} from './centers';

const routesConfig = [
  ...dashboardRouteConfig,
  // ...errorPagesRouteConfig,
  ...managementRouteConfig,
  ...centerRouteConfig,
  // ...newsFeedRouteConfig,
  // ...reportsRouteConfig
];
export default routesConfig;
