import React from 'react';

export const errorPagesRouteConfig = [
  {
    id: 'pages',
    title: 'Pages',
    messageId: 'sidebar.pages',
    type: 'group',
    children: [
      {
        id: 'error-pages',
        title: 'Error Pages',
        messageId: 'sidebar.pages.errorPages',
        type: 'collapse',
        icon: 'report',
        children: [
          {
            id: 'error-404',
            title: '404',
            messageId: 'sidebar.pages.errorPages.404',
            type: 'item',
            url: '/error-pages/error-404',
          },
          {
            id: 'error-500',
            title: '500',
            messageId: 'sidebar.pages.errorPages.500',
            type: 'item',
            url: '/error-pages/error-500',
          },
          {
            id: 'maintenance',
            title: 'Maintenance',
            messageId: 'sidebar.pages.errorPages.maintenance',
            type: 'item',
            url: '/error-pages/maintenance',
          },
          {
            id: 'coming-soon',
            title: 'Coming Soon',
            messageId: 'sidebar.pages.errorPages.comingSoon',
            type: 'item',
            url: '/error-pages/coming-soon',
          },
        ],
      },
    ],
  },
];
export const errorPagesConfigs = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('./Error404')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/error-pages/error-500',
        component: React.lazy(() => import('./Error500')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/error-pages/coming-soon',
        component: React.lazy(() => import('./ComingSoon')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/error-pages/maintenance',
        component: React.lazy(() => import('./Maintenance')),
      },
    ],
  },
];
