//  Redux Imports
import {fetchError, fetchStart, fetchSuccess} from '../Common';
import {Dispatch} from 'redux';

/**
 *  Utility Imports
 */
import ApiService from '@crema/services/ApiService';
import {notificationApi} from 'shared/constants/AppConst';
import {
  ADD_NOTIFICATION_TABLE_PARAMS,
  GET_NOTIFICATIONS_DATA,
  ManagementActionTypes,
  VIEW_NOTIFICATION_FINISH,
  VIEW_NOTIFICATION_START,
} from 'types/actions/Management.action';
import {NotificationModel, PaginationData} from 'types/models/Common';
import {AppActions} from 'types';

/**
 * Notification Actions
 */
export const onGetNotificationsData = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(`${notificationApi.getAll}`);

      const result = {
        data: data.data,
        viewing: false,
        selectedNotification: null,
        pagination: {
          start: 0,
          limit: 10,
          page: 0,
          count: data.total, //total
          tableParams: {},
        },
      };

      dispatch({
        type: GET_NOTIFICATIONS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onViewNotificationStart = (notification: NotificationModel) => ({
  type: VIEW_NOTIFICATION_START,
  payload: notification,
});

export const onViewNotificationFinish = () => ({
  type: VIEW_NOTIFICATION_FINISH,
});

export const setNotificationCurrentTablePage = (
  page: number,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {limit, tableParams} = paginationData;
      const {sortBy, order, filters} = tableParams;
      const {id, title, message, status, isRead} = filters || {};
      let queryString = '';

      if (id) queryString = `&id=${id}`;
      if (title) queryString += `&firstName=${title}`;
      if (message) queryString += `&message=${message}`;
      if (status) queryString += `&status=${status}`;
      if (isRead) queryString += `&isRead=${isRead}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      const start = page * limit;
      let data = await ApiService.get(
        notificationApi.get(start, limit, queryString),
      );

      const result = {
        data: data.data,
        viewing: false,
        selectedNotification: null,
        pagination: {
          start,
          page,
          limit,
          count: data.total, //total
          tableParams: {
            ...tableParams,
          },
        },
      };

      dispatch({
        type: GET_NOTIFICATIONS_DATA,
        payload: result,
      });
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const setNotificationRowsPerPage = (
  limit: number,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {limit, tableParams} = paginationData;
      const {sortBy, order, filters} = tableParams;
      const {id, title, message, status, isRead} = filters || {};
      let queryString = '';

      if (id) queryString = `&id=${id}`;
      if (title) queryString += `&firstName=${title}`;
      if (message) queryString += `&message=${message}`;
      if (status) queryString += `&status=${status}`;
      if (isRead) queryString += `&isRead=${isRead}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      let data = await ApiService.get(
        notificationApi.get(0, limit, queryString),
      );

      const result = {
        data: data.data,
        viewing: false,
        selectedNotification: null,
        pagination: {
          start: 0,
          page: 0,
          limit,
          count: data.total,
          tableParams: {...tableParams},
        },
      };

      dispatch({
        type: GET_NOTIFICATIONS_DATA,
        payload: result,
      });
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const addNotificationTableParams = (
  tableParams: any,
): ManagementActionTypes => ({
  type: ADD_NOTIFICATION_TABLE_PARAMS,
  payload: tableParams,
});

export const setNotificationSorting = (
  pagination: PaginationData['pagination'],
  sortBy: string,
  order: string,
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const tableParams = {
        ...pagination.tableParams,
        sortBy,
        order,
      };

      dispatch(addNotificationTableParams(tableParams));

      let data = await ApiService.get(
        notificationApi.get(
          pagination.start,
          pagination.limit,
          `&_sortBy=${sortBy}&_order=${order}`,
        ),
      );

      const result = {
        data: data.data,
        viewing: false,
        selectedNotification: null,
        pagination: {
          ...pagination,
          count: data.total, //total
          tableParams: {sortBy, order},
        },
      };

      dispatch({
        type: GET_NOTIFICATIONS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onFilterNotifications = (
  values: any,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {limit, tableParams} = paginationData;
      const {sortBy, order} = tableParams;
      const {id, title, message, status, isRead} = values || {};
      let queryString = '';

      if (id) queryString = `&id=${id}`;
      if (title) queryString += `&firstName=${title}`;
      if (message) queryString += `&message=${message}`;
      if (status) queryString += `&status=${status}`;
      if (isRead) queryString += `&isRead=${isRead}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      let data = await ApiService.get(
        notificationApi.get(0, limit, queryString),
      );

      const result = {
        data: data.data,
        viewing: false,
        selectedNotification: null,
        pagination: {
          start: 0,
          page: 0,
          limit,
          count: data.total, //total
          tableParams: {
            ...tableParams,
            filters: {
              ...values,
            },
          },
        },
      };

      queryString = '';

      dispatch({
        type: GET_NOTIFICATIONS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};
