import {AxiosInstance, AxiosResponse} from 'axios';
import Api from './ApiConfig';

class ApiService {
  public api: AxiosInstance;

  constructor() {
    this.api = Api;
  }

  public get = async (url: string) => {
    try {
      const response: AxiosResponse = await this.api.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response && response.status === 200 && response.data) {
        return response.data;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

  public post = async (url: string, data: {[key: string]: any}) => {
    try {
      const response: AxiosResponse = await this.api.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (
        response &&
        response.data &&
        (response.status === 200 || response.status === 201)
      ) {
        return response.data;
      }
    } catch (err) {
      console.log({err});
      if (err?.response?.message) throw new Error(err.response.message);
      else throw new Error(err.message);
    }
  };

  public put = async (url: string, data: {[key: string]: any} = {}) => {
    try {
      const response: AxiosResponse = await this.api.put(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response && response.data) {
        return response.data;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

  public delete = async (url: string) => {
    try {
      const response: AxiosResponse = await this.api.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response && response.data) {
        return response.data;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
}

export default new ApiService();
