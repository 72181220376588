import Api from '../../@crema/services/ApiConfig';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import {GET_ANALYTICS_DATA} from '../../types/actions/Dashboard.action';
import {
  CenterModelResponse,
  PatientModelResponse,
  PickupRequestDataMock,
} from 'types/models/Analytics';
import {AxiosPromise, AxiosResponse} from 'axios';

export const onGetAnalyticsData = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const getPickupMock = (waitFor: number) =>
        new Promise<PickupRequestDataMock>((resolve, _) => {
          setTimeout(() => {
            resolve({
              status: 200,
              statusText: 'OK',
              headers: {},
              config: {},
              request: {},
              data: {
                pending: 32,
                closed: 320,
                unavailable: 15,
              },
            });
          }, waitFor);
        });

      // const getPharmacists: AxiosPromise<PharmacistModelResponse[]> = Api({
      //   url: '/pharmacist',
      // });
      const getPatientLocations: AxiosPromise<PatientModelResponse[]> = Api({
        url: '/patient/location',
      });
      const getCenterLocation: AxiosPromise<CenterModelResponse[]> = Api({
        url: '/center',
      });
      const getPatientCount: AxiosPromise<CenterModelResponse[]> = Api({
        url: '/patient/count',
      });
      const getPhcCount: AxiosPromise<CenterModelResponse[]> = Api({
        url: '/phc/count',
      });
      const getPharmaciesCount: AxiosPromise<CenterModelResponse[]> = Api({
        url: '/pharmacies/count',
      });
      const getCenterCount: AxiosPromise<CenterModelResponse[]> = Api({
        url: '/center/count',
      });
      const getHomeDeliveryCount: AxiosPromise<CenterModelResponse[]> = Api({
        url: '/hd/count',
      });

      const getPickup = getPickupMock(400);

      const resolver = await Promise.all([
        // getPharmacists,
        //
        getPatientLocations,
        getCenterLocation,
        getPickup,
        getPatientCount,
        getPhcCount,
        getPharmaciesCount,
        getCenterCount,
        getHomeDeliveryCount,
      ]);

      const pullData = (data: AxiosResponse<any>) =>
        data.data.data ? data.data.data : data.data;

      // const pharmacists = pullData(resolver[0]);
      const patients = pullData(resolver[0]);
      const centers = pullData(resolver[1]);
      const pickup = pullData(resolver[2]);
      const patientCount = pullData(resolver[3]);
      const phcCount = pullData(resolver[4]);
      const pharmaciesCount = pullData(resolver[5]);
      const centerCount = pullData(resolver[6]);
      const hdCount = pullData(resolver[7]);

      const pullLatLon = <
        T extends
          | Pick<CenterModelResponse, 'lat' | 'lng'>
          | Pick<PatientModelResponse, 'lng' | 'lat'>
      >(
        data: T[],
      ) => {
        console.log('test', data);

        return data.map((i) => ({lng: i.lng, lat: i.lat}));
      };

      const result = {
        quickStatsData: {
          numberOfDownloads: 0,
          patientCount,
          pharmaciesCount,
          phcCount,
          centerCount,
          hdCount,
        },
        pickupRequestsData: pickup,
        mapWidgetData: {
          patientsLocationData: pullLatLon(patients),
          centersLocationData: pullLatLon(centers),
        },
      };

      dispatch({
        type: GET_ANALYTICS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());

      return result;
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};
