import {
  DashboardActionTypes,
  GET_ANALYTICS_DATA,
} from '../../types/actions/Dashboard.action';
import {Analytics} from '../../types/models/Analytics';

const initialState: {
  analyticsData: Analytics | null;
} = {
  analyticsData: null,
};

export default (state = initialState, action: DashboardActionTypes) => {
  switch (action.type) {
    case GET_ANALYTICS_DATA:
      return {
        ...state,
        analyticsData: action.payload,
      };
    default:
      return state;
  }
};
