/**
 *  Redux Imports
 */
import {Dispatch} from 'redux';
import {fetchError, fetchStart, fetchSuccess} from '../Common';
import {AppActions} from 'types';
import {
  GET_ADMIN_MANAGEMENT_DATA,
  EDIT_ADMIN_INFORMATION_START,
  EDIT_ADMIN_INFORMATION_FINISH,
  OPEN_ADMIN_DELETE_DIALOG,
  CLOSE_ADMIN_DELETE_DIALOG,
  GET_PATIENTS_DATA,
  OPEN_PATIENT_NOTIFICATON_DIALOG,
  EDIT_PATIENT_INFORMATION_FINISH,
  CLOSE_PATIENT_DELETE_DIALOG,
  OPEN_PATIENT_DELETE_DIALOG,
  EDIT_PATIENT_INFORMATION_START,
  CLOSE_PATIENT_NOTIFICATON_DIALOG,
  ADD_PATIENT_TABLE_PARAMS,
  ADD_ADMIN_TABLE_PARAMS,
  ManagementActionTypes,
} from 'types/actions/Management.action';
import {
  AdminModel,
  PatientUserModel,
  PaginationData,
} from 'types/models/Common';

/**
 *  Utility Imports
 */
import ApiService from '@crema/services/ApiService';
import {adminApiUrl, patientApi} from 'shared/constants/AppConst';

export const onGetAdminsManagementData = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(adminApiUrl.getAll);

      const result = {
        data: data.data,
        editing: false,
        pagination: {
          start: 0,
          limit: 10,
          page: 0,
          count: data.total, //total
          tableParams: {},
        },
        editingUser: null,
        deleting: false,
        deletingUser: null,
      };

      dispatch({
        type: GET_ADMIN_MANAGEMENT_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onEditAdminInformationStart = (admin: AdminModel) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch({
        type: EDIT_ADMIN_INFORMATION_START,
        payload: admin,
      });
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onEditAdminInformationFinish = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: EDIT_ADMIN_INFORMATION_FINISH,
    });
  };
};

export const openAdminDeleteDialog = (payload: AdminModel) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: OPEN_ADMIN_DELETE_DIALOG,
      payload,
    });
  };
};

export const closeAdminDeleteDialog = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: CLOSE_ADMIN_DELETE_DIALOG,
    });
  };
};

export const setAdminCurrentTablePage = (
  page: number,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {limit, tableParams} = paginationData;

      const {sortBy, order, filters} = tableParams;

      const {id, name, email, phone, isActive, adminType} = filters || {};

      let queryString = '';

      if (id) queryString = `&id=${id}`;
      if (name) queryString += `&name=${name}`;
      if (phone) queryString += `&phone=${phone}`;
      if (email) queryString += `&email=${email}`;
      if (isActive) queryString += `&isActive=${isActive}`;
      if (adminType) queryString += `&adminType=${adminType}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      const start = page * limit;
      let data = await ApiService.get(
        adminApiUrl.get(start, limit, queryString),
      );

      const result = {
        data: data.data,
        editing: false,
        pagination: {
          start,
          page,
          limit,
          count: data.total, //total
          tableParams: {
            ...tableParams,
          },
        },
        editingUser: null,
        deleting: false,
        deletingUser: null,
      };

      dispatch({
        type: GET_ADMIN_MANAGEMENT_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const setAdminRowsPerPage = (
  limit: number,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {tableParams} = paginationData;
      const {sortBy, order, filters} = tableParams;
      const {id, name, email, phone, isActive, adminType} = filters || {};
      let queryString = '';

      if (id) queryString = `&id=${id}`;
      if (name) queryString += `&name=${name}`;
      if (phone) queryString += `&phone=${phone}`;
      if (email) queryString += `&email=${email}`;
      if (isActive) queryString += `&isActive=${isActive}`;
      if (adminType) queryString += `&adminType=${adminType}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      let data = await ApiService.get(adminApiUrl.get(0, limit, queryString));

      const result = {
        data: data.data,
        editing: false,
        pagination: {
          start: 0,
          page: 0,
          limit: limit,
          count: data.total,
          tableParams: {...tableParams},
        },
        editingUser: null,
        deleting: false,
        deletingUser: null,
      };

      dispatch({
        type: GET_ADMIN_MANAGEMENT_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const addAdminTableParams = (
  tableParams: any,
): ManagementActionTypes => ({
  type: ADD_ADMIN_TABLE_PARAMS,
  payload: tableParams,
});

export const setAdminSorting = (
  pagination: PaginationData['pagination'],
  sortBy: string,
  order: string,
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const tableParams = {
        ...pagination.tableParams,
        sortBy,
        order,
      };

      dispatch(addAdminTableParams(tableParams));

      let data = await ApiService.get(
        adminApiUrl.get(
          pagination.start,
          pagination.limit,
          `&_sortBy=${sortBy}&_order=${order}`,
        ),
      );

      const result = {
        data: data.data,
        editing: false,
        pagination: {
          ...pagination,
          count: data.total, //total
          tableParams: {sortBy, order},
        },
        editingUser: null,
        deleting: false,
        deletingUser: null,
        sending: false,
        selectedPatient: null,
      };

      dispatch({
        type: GET_ADMIN_MANAGEMENT_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onFilterAdmins = (
  values: any,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {limit, tableParams} = paginationData;
      const {sortBy, order} = tableParams;
      const {id, name, email, phone, isActive, adminType} = values || {};

      let queryString = '';

      if (id) queryString = `&id=${id}`;
      if (name) queryString += `&name=${name}`;
      if (phone) queryString += `&phone=${phone}`;
      if (email) queryString += `&email=${email}`;
      if (isActive) queryString += `&isActive=${isActive}`;
      if (adminType) queryString += `&adminType=${adminType}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      let data = await ApiService.get(adminApiUrl.get(0, limit, queryString));

      const result = {
        data: data.data,
        editing: false,
        pagination: {
          start: 0,
          page: 0,
          limit,
          count: data.total, //total
          tableParams: {
            ...tableParams,
            filters: {
              id,
              name,
              phone,
              email,
              isActive,
              adminType,
            },
          },
        },
        editingUser: null,
        deleting: false,
        deletingUser: null,
      };

      queryString = '';

      dispatch({
        type: GET_ADMIN_MANAGEMENT_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

/**
 * Patient Actions
 */
export const onGetPatientsManagementData = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(`${patientApi.getAll}`);

      const result = {
        data: data.data,
        editing: false,
        pagination: {
          start: 0,
          limit: 10,
          page: 0,
          count: data.total, //total
          tableParams: {},
        },
        editingUser: null,
        deleting: false,
        deletingUser: null,
        sending: false,
        selectedPatient: null,
      };

      dispatch({
        type: GET_PATIENTS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const openPatientNotificationDialog = (patient: PatientUserModel) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch({
        type: OPEN_PATIENT_NOTIFICATON_DIALOG,
        payload: patient,
      });
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const closePatientNotificationDialog = () => ({
  type: CLOSE_PATIENT_NOTIFICATON_DIALOG,
});

export const onEditPatientInformationStart = (patient: PatientUserModel) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch({
        type: EDIT_PATIENT_INFORMATION_START,
        payload: patient,
      });
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onEditPatientInformationFinish = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: EDIT_PATIENT_INFORMATION_FINISH,
    });
  };
};

export const openPatientDeleteDialog = (payload: PatientUserModel) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: OPEN_PATIENT_DELETE_DIALOG,
      payload,
    });
  };
};

export const closePatientDeleteDialog = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: CLOSE_PATIENT_DELETE_DIALOG,
    });
  };
};

export const setPatientCurrentTablePage = (
  page: number,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {limit, tableParams} = paginationData;
      const {sortBy, order, filters} = tableParams;
      let queryString = '';

      // || {
      //   sortBy: '',
      //   order: '',
      //   filters: {},
      // };

      const {
        NID,
        firstname,
        lastname,
        mobileNumber,
        status,
        gender,
        deviceType,
      } = filters || {};

      if (NID) queryString = `&NID=${NID}`;
      if (firstname) queryString += `&firstName=${firstname}`;
      if (lastname) queryString += `&lastName=${lastname}`;
      if (mobileNumber) queryString += `&mobileNumber=${mobileNumber}`;
      if (status) queryString += `&Active=${status}`;
      if (gender) queryString += `&gender=${gender}`;
      if (deviceType) queryString += `&deviceType=${deviceType}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      // if (queryString) queryString = queryString.slice(1, queryString.length);

      const start = page * limit;

      let data = await ApiService.get(
        patientApi.get(start, limit, queryString),
      );

      const result = {
        data: data.data,
        editing: false,
        pagination: {
          start,
          page,
          limit,
          count: data.total, //total
          tableParams: {
            ...tableParams,
          },
        },
        editingUser: null,
        deleting: false,
        deletingUser: null,
        sending: false,
        selectedPatient: null,
      };

      dispatch({
        type: GET_PATIENTS_DATA,
        payload: result,
      });
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const setPatientRowsPerPage = (
  limit: number,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {tableParams} = paginationData;
      const {sortBy, order, filters} = tableParams;
      // || {
      //   sortBy: '',
      //   order: '',
      //   filters: {},
      // };

      let queryString = '';

      const {
        NID,
        firstname,
        lastname,
        mobileNumber,
        status,
        gender,
        deviceType,
      } = filters || {};

      if (NID) queryString = `&NID=${NID}`;
      if (firstname) queryString += `&firstName=${firstname}`;
      if (lastname) queryString += `&lastName=${lastname}`;
      if (mobileNumber) queryString += `&mobileNumber=${mobileNumber}`;
      if (status) queryString += `&Active=${status}`;
      if (gender) queryString += `&gender=${gender}`;
      if (deviceType) queryString += `&deviceType=${deviceType}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      // if (queryString) queryString = queryString.slice(1, queryString.length);

      let data = await ApiService.get(patientApi.get(0, limit, queryString));

      const result = {
        data: data.data,
        editing: false,
        pagination: {
          start: 0,
          page: 0,
          limit: limit,
          count: data.total, //total
          tableParams: {
            ...tableParams,
          },
        },
        editingUser: null,
        deleting: false,
        deletingUser: null,
        sending: false,
        selectedPatient: null,
      };

      dispatch({
        type: GET_PATIENTS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const addPatientTableParams = (
  tableParams: any,
): ManagementActionTypes => ({
  type: ADD_PATIENT_TABLE_PARAMS,
  payload: tableParams,
});

export const setPatientSorting = (
  pagination: PaginationData['pagination'],
  sortBy: string,
  order: string,
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const tableParams = {
        ...pagination.tableParams,
        sortBy,
        order,
      };

      dispatch(addPatientTableParams(tableParams));

      let data = await ApiService.get(
        patientApi.get(
          pagination.start,
          pagination.limit,
          `&_sortBy=${sortBy}&_order=${order}`,
        ),
      );

      const result = {
        data: data.data,
        editing: false,
        pagination: {
          ...pagination,
          tableParams: {sortBy, order},
          count: data.total, //total
        },
        editingUser: null,
        deleting: false,
        deletingUser: null,
        sending: false,
        selectedPatient: null,
      };

      dispatch({
        type: GET_PATIENTS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onFilterPatients = (
  values: any,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      console.log(values, 'filter these');

      const {limit, tableParams} = paginationData;
      const {sortBy, order} = tableParams;
      const {
        NID,
        firstname,
        lastname,
        mobileNumber,
        status,
        gender,
        deviceType,
      } = values;

      let queryString = '';
      if (NID) queryString = `&NID=${NID}`;
      if (firstname) queryString += `&firstName=${firstname}`;
      if (lastname) queryString += `&lastName=${lastname}`;
      if (mobileNumber) queryString += `&mobileNumber=${mobileNumber}`;
      if (status) queryString += `&Active=${status}`;
      if (gender) queryString += `&gender=${gender}`;
      if (deviceType) queryString += `&deviceType=${deviceType}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      console.log(queryString);
      let data = await ApiService.get(patientApi.get(0, limit, queryString));

      const result = {
        data: data.data,
        editing: false,
        pagination: {
          start: 0,
          page: 0,
          limit: limit,
          count: data.total, //total
          tableParams: {
            ...tableParams,
            filters: {
              NID,
              firstname,
              lastname,
              mobileNumber,
              status,
              gender,
              deviceType,
            },
          },
        },
        editingUser: null,
        deleting: false,
        deletingUser: null,
        sending: false,
        selectedPatient: null,
      };

      queryString = '';

      dispatch({
        type: GET_PATIENTS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};
