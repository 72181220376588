import {fetchError, fetchStart, fetchSuccess} from '../Common';
import {AppActions} from '../../../types';
import {Dispatch} from 'redux';
import ApiService from '@crema/services/ApiService';
import {
  ADD_CENTER_TABLE_PARAMS,
  CenterActionTypes,
  CLOSE_CENTER_DELETE_DIALOG,
  EDIT_CENTER_INFORMATION_FINISH,
  EDIT_CENTER_INFORMATION_START,
  GET_CENTER_DATA,
  OPEN_CENTER_DELETE_DIALOG,
} from 'types/actions/Center.action';
import {CenterModel} from 'types/models/Center';
import {centerApi} from 'shared/constants/AppConst';
import {PaginationData} from 'types/models/Common';

/**
 * Content Actions
 */

export const onGetCenterData = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(centerApi.getAll);

      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,
        pagination: {
          start: 0,
          limit: 10,
          page: 0,
          count: data.total, //total
          tableParams: {},
        },
      };

      dispatch({
        type: GET_CENTER_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onEditCenterInformationStart = (content: CenterModel) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch({
        type: EDIT_CENTER_INFORMATION_START,
        payload: content,
      });

      return content;
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onEditCenterInformationFinish = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: EDIT_CENTER_INFORMATION_FINISH,
    });
  };
};

export const openCenterDeleteDialog = (payload: CenterModel) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: OPEN_CENTER_DELETE_DIALOG,
      payload,
    });
  };
};

export const closeCenterDeleteDialog = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: CLOSE_CENTER_DELETE_DIALOG,
    });
  };
};

export const setCenterCurrentTablePage = (
  page: number,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {limit, tableParams} = paginationData;
      const {sortBy, order, filters} = tableParams;
      let queryString = '';

      const {id, name, contactNumber} = filters || {};

      if (id) queryString = `&id=${id}`;
      if (name) queryString += `&name=${name}`;
      if (contactNumber) queryString += `&contactNumber=${contactNumber}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      const start = page * limit;
      let data = await ApiService.get(centerApi.get(start, limit, queryString));

      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,
        pagination: {
          start,
          page,
          limit,
          count: data.total, //total
          tableParams: {
            ...tableParams,
          },
        },
      };

      dispatch({
        type: GET_CENTER_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const setCenterRowsPerPage = (
  limit: number,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {tableParams} = paginationData;
      const {sortBy, order, filters} = tableParams;
      let queryString = '';

      const {id, name, contactNumber} = filters || {};

      if (id) queryString = `&id=${id}`;
      if (name) queryString += `&name=${name}`;
      if (contactNumber) queryString += `&contactNumber=${contactNumber}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      let data = await ApiService.get(centerApi.get(0, limit, queryString));

      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,
        pagination: {
          start: 0,
          page: 0,
          limit,
          count: data.total, //total
          tableParams: {
            ...tableParams,
          },
        },
      };

      dispatch({
        type: GET_CENTER_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const addCenterTableParams = (tableParams: any): CenterActionTypes => ({
  type: ADD_CENTER_TABLE_PARAMS,
  payload: tableParams,
});

export const setCenterSorting = (
  pagination: PaginationData['pagination'],
  sortBy: string,
  order: string,
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const tableParams = {
        ...pagination.tableParams,
        sortBy,
        order,
      };

      dispatch(addCenterTableParams(tableParams));

      let data = await ApiService.get(
        centerApi.get(
          pagination.start,
          pagination.limit,
          `&_sortBy=${sortBy}&_order=${order}`,
        ),
      );
      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,
        pagination: {
          ...pagination,
          tableParams: {sortBy, order},
          count: data.total, //total
        },
      };

      dispatch({
        type: GET_CENTER_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onFilterCenters = (
  values: any,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      console.log(values, 'filter these');

      const {limit, tableParams} = paginationData;
      const {sortBy, order} = tableParams;
      let queryString = '';

      const {id, name, contactNumber} = values;

      if (id) queryString = `&id=${id}`;
      if (name) queryString += `&name=${name}`;
      if (contactNumber) queryString += `&contactNumber=${contactNumber}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      console.log(queryString);
      let data = await ApiService.get(centerApi.get(0, limit, queryString));

      const result = {
        data: data.data,
        editing: false,
        selectedItem: null,
        deleting: false,
        pagination: {
          start: 0,
          page: 0,
          limit: limit,
          count: data.total, //total
          tableParams: {
            ...tableParams,
            filters: {
              ...values,
            },
          },
        },
      };

      queryString = '';

      dispatch({
        type: GET_CENTER_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};
