export enum ThemeStyle {
  MODERN = 'modern',
  STANDARD = 'standard',
}

export enum ThemeStyleRadius {
  MODERN = 30,
  STANDARD = 4,
}

export enum ThemeMode {
  LIGHT = 'light',
  SEMI_DARK = 'semi-dark',
  DARK = 'dark',
}

export enum LayoutType {
  FULL_WIDTH = 'full-width',
  BOXED = 'boxed',
}

export enum NavStyle {
  DEFAULT = 'default',
  MINI = 'mini',
  STANDARD = 'standard',
  HEADER_USER = 'user-header',
  HEADER_USER_MINI = 'user-mini-header',
  DRAWER = 'drawer',
  BIT_BUCKET = 'bit-bucket',
  H_DEFAULT = 'h-default',
  HOR_LIGHT_NAV = 'hor-light-nav',
  HOR_DARK_LAYOUT = 'hor-dark-layout',
}

export enum FooterType {
  FIXED = 'fixed',
  FLUID = 'fluid',
}

export enum HeaderType {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum RouteTransition {
  NONE = 'none',
  FADE = 'fade',
  SLIDE_LEFT = 'slideLeft',
  SLIDE_RIGHT = 'slideRight',
  SLIDE_UP = 'slideUp',
  SLIDE_DOWN = 'slideDown',
}

export enum Fonts {
  LIGHT = 'DIN Next LT Arabic-Light',
  REGULAR = 'DIN Next LT Arabic-Regular',
  MEDIUM = 'DIN Next LT Arabic-Medium',
  BOLD = 'DIN Next LT Arabic-Bold',
  EXTRA_BOLD = 'DIN Next LT Arabic-Heavy',
}

export enum Languages {
  en = 'en',
  ar = 'ar',
}

export enum DeviceType {
  ANDROID = 'android',
  IOS = 'ios',
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export enum IsReadEnum {
  READ = 'yes',
  UNREAD = 'no',
}
