import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
// import { auth as firebaseAuth } from '../services/auth/firebase/firebase';
import { AppState } from '../../redux/store';
import { UPDATE_FIREBASE_USER } from '../../types/actions/Auth.actions';
import { AuthUser } from "../../types/models/AuthUser";
import { isAuthTokenExpired } from './Utils';

export const useAuthToken = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string | null>(null);
  const { user } = useSelector<AppState, AppState['auth']>(({ auth }) => auth);
  const authUser = user;
  useEffect(() => {

    // const firebaseCheck = () =>
    //   new Promise((resolve) => {
    //     firebaseAuth.onAuthStateChanged((authUser) => {
    //       if (authUser) {
    //         dispatch({
    //           type: UPDATE_FIREBASE_USER,
    //           payload: {
    //             uid: authUser.uid,
    //             displayName: authUser.displayName,
    //             email: authUser.email,
    //             photoURL: authUser.photoURL,
    //             token: authUser.refreshToken,
    //           },
    //         });
    //       }
    //       resolve();
    //     });
    //     return Promise.resolve();
    //   });

    const authCheck = () => {
      new Promise((resolve) => {
        const data = localStorage.getItem('userInfo');
        const userInfo: AuthUser = data ? JSON.parse(data) : null;
        if (userInfo && userInfo.token && !isAuthTokenExpired(userInfo.token)) {
          setToken(userInfo.token);
          dispatch({
            type: UPDATE_FIREBASE_USER,
            payload: {
              uid: userInfo!.uid,
              displayName: userInfo.displayName,
              email: userInfo.email,
              photoURL: userInfo.photoURL,
              token: userInfo.token,
            },
          });
        }
        resolve();
      });
      return Promise.resolve();
    }
    
    const checkAuth = () => {
      Promise.all([authCheck()]).then(() => {
        setLoading(false);
      });
    };
    checkAuth();
  }, [dispatch]);

  useEffect(() => {
    if (!authUser) {
      setToken(null);
    }
    if (authUser && authUser.token) {
      console.log('authUser.token: ', authUser.token);
      setToken(authUser.token);
    }
  }, [authUser]);

  return [token, loading];
};

export const useAuthUser = () => {
  const { user } = useSelector<AppState, AppState['auth']>(({ auth }) => auth);
  if (user) {
    return user;
  }
  return null;
};
