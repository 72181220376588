import axios from 'axios';
import {baseApiUrl} from '../../shared/constants/AppConst';

const Api = axios.create({
  baseURL: baseApiUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export default Api;
