import {fetchError, fetchStart, fetchSuccess} from '../Common';
import {AppActions} from '../../../types';
import {Dispatch} from 'redux';
import {
  GET_CONTENTS_DATA,
  GET_CATEGORIES,
  EDIT_CONTENT_INFORMATION_FINISH,
  EDIT_CONTENT_INFORMATION_START,
  OPEN_CONTENT_DELETE_DIALOG,
  CLOSE_CONTENT_DELETE_DIALOG,
  ManagementActionTypes,
  ADD_CONTENT_TABLE_PARAMS,
} from '../../../types/actions/Management.action';
import {ContentModel, PaginationData} from 'types/models/Common';
import ApiService from '@crema/services/ApiService';
import {contentApi} from 'shared/constants/AppConst';

/**
 * Content Actions
 */

export const onGetContentsManagementData = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(`${contentApi.getAll}`);

      const result = {
        data: data.data,
        editing: false,
        selectedContent: null,
        deleting: false,
        pagination: {
          start: 0,
          limit: 10,
          page: 0,
          count: data.total, //total
          tableParams: {},
        },
      };

      dispatch({
        type: GET_CONTENTS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onEditContentInformationStart = (content: ContentModel) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch({
        type: EDIT_CONTENT_INFORMATION_START,
        payload: content,
      });

      return content;
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onEditContentInformationFinish = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: EDIT_CONTENT_INFORMATION_FINISH,
    });
  };
};

export const openContentDeleteDialog = (payload: ContentModel) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: OPEN_CONTENT_DELETE_DIALOG,
      payload,
    });
  };
};

export const closeContentDeleteDialog = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: CLOSE_CONTENT_DELETE_DIALOG,
    });
  };
};

export const setContentCurrentTablePage = (
  page: number,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const {limit, tableParams} = paginationData;
      const {sortBy, order, filters} = tableParams;
      const {id, isPublished} = filters || {};

      let queryString = '';

      if (id) queryString = `&id=${id}`;
      if (isPublished) queryString += `&isPublished=${isPublished}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      const start = page * limit;
      let data = await ApiService.get(
        contentApi.get(start, limit, queryString),
      );

      const result = {
        data: data.data,
        editing: false,
        selectedContent: null,
        deleting: false,
        pagination: {
          start,
          page,
          limit,
          count: data.total, //total
          tableParams: {
            ...tableParams,
          },
        },
      };

      dispatch({
        type: GET_CONTENTS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const setContentRowsPerPage = (
  limit: number,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());
      const {tableParams} = paginationData;

      const {sortBy, order, filters} = tableParams;

      const {id, isPublished} = filters || {};

      let queryString = '';

      if (id) queryString = `&id=${id}`;
      if (isPublished) queryString += `&isPublished=${isPublished}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      let data = await ApiService.get(contentApi.get(0, limit, queryString));

      const result = {
        data: data.data,
        editing: false,
        selectedContent: null,
        deleting: false,
        pagination: {
          start: 0,
          page: 0,
          limit,
          count: data.total,
          tableParams: {
            ...tableParams,
          },
        },
      };

      dispatch({
        type: GET_CONTENTS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const addContentTableParams = (
  tableParams: any,
): ManagementActionTypes => ({
  type: ADD_CONTENT_TABLE_PARAMS,
  payload: tableParams,
});

export const setContentSorting = (
  pagination: PaginationData['pagination'],
  sortBy: string,
  order: string,
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const tableParams = {
        ...pagination.tableParams,
        sortBy,
        order,
      };

      dispatch(addContentTableParams(tableParams));

      let data = await ApiService.get(
        contentApi.get(
          pagination.start,
          pagination.limit,
          `&_sortBy=${sortBy}&_order=${order}`,
        ),
      );

      const result = {
        data: data.data,
        editing: false,
        selectedContent: null,
        deleting: false,
        pagination: {
          ...pagination,
          tableParams: {sortBy, order},
          count: data.total, //total
        },
      };

      dispatch({
        type: GET_CONTENTS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onFilterContents = (
  values: any,
  paginationData: PaginationData['pagination'],
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());
      console.log('START');

      console.log(values, 'filter these');

      const {limit, tableParams} = paginationData;
      const {sortBy, order} = tableParams;
      const {id, isPublished} = values;

      let queryString = '';
      if (id) queryString = `&id=${id}`;
      if (isPublished) queryString += `&isPublished=${isPublished}`;
      if (sortBy) queryString += `&_sortBy=${sortBy}`;
      if (order) queryString += `&_order=${order}`;

      let data = await ApiService.get(contentApi.get(0, limit, queryString));

      const result = {
        data: data.data,
        editing: false,
        selectedContent: null,
        deleting: false,
        pagination: {
          start: 0,
          page: 0,
          limit: limit,
          count: data.total, //total
          tableParams: {
            ...tableParams,
            filters: {
              id,
              isPublished,
            },
          },
        },
      };

      queryString = '';

      dispatch({
        type: GET_CONTENTS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const getCategories = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());

      const data = await ApiService.get('/category');
      if (data && Array.isArray(data)) {
        dispatch({
          type: GET_CATEGORIES,
          payload: data,
        });
        dispatch(fetchSuccess());
      }
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};
