import {Analytics} from '../models/Analytics';

export const GET_ANALYTICS_DATA = 'GET_ANALYTICS_DATA';

export interface GetAnalyticsAction {
  type: typeof GET_ANALYTICS_DATA;
  payload: Analytics;
}

export type DashboardActionTypes = GetAnalyticsAction;
