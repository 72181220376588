import {CategoryModel} from 'types/models/Common';
import {
  EDIT_ADMIN_INFORMATION_FINISH,
  EDIT_ADMIN_INFORMATION_START,
  GET_ADMIN_MANAGEMENT_DATA,
  ManagementActionTypes,
  OPEN_ADMIN_DELETE_DIALOG,
  CLOSE_ADMIN_DELETE_DIALOG,
  GET_PATIENTS_DATA,
  ADD_PATIENT_TABLE_PARAMS,
  OPEN_PATIENT_NOTIFICATON_DIALOG,
  CLOSE_PATIENT_NOTIFICATON_DIALOG,
  EDIT_PATIENT_INFORMATION_START,
  EDIT_PATIENT_INFORMATION_FINISH,
  OPEN_PATIENT_DELETE_DIALOG,
  CLOSE_PATIENT_DELETE_DIALOG,
  GET_CONTENTS_DATA,
  GET_CATEGORIES,
  EDIT_CONTENT_INFORMATION_START,
  EDIT_CONTENT_INFORMATION_FINISH,
  OPEN_CONTENT_DELETE_DIALOG,
  CLOSE_CONTENT_DELETE_DIALOG,
  GET_NOTIFICATIONS_DATA,
  VIEW_NOTIFICATION_START,
  VIEW_NOTIFICATION_FINISH,
  ADD_ADMIN_TABLE_PARAMS,
  ADD_CONTENT_TABLE_PARAMS,
  ADD_NOTIFICATION_TABLE_PARAMS,
} from '../../types/actions/Management.action';
import {
  AdminManagement,
  ContentManagementData,
  NotificationManagementData,
  PatientManagementData,
} from '../../types/models/Management';

type ManagementReducerState = {
  adminManagementData: AdminManagement;
  patientData: PatientManagementData;
  contentData: ContentManagementData;
  categoryData: CategoryModel[];
  notificationData: NotificationManagementData;
};

const initialState: ManagementReducerState = {
  adminManagementData: {
    data: [],
    editing: false,

    pagination: {
      start: 0,
      limit: 10,
      page: 0,
      count: 0,
      tableParams: {
        sortBy: '',
        order: '',
        filters: {
          id: '',
          name: '',
          email: '',
          phone: '',
          isActive: '',
          adminType: '',
        },
      },
    },
    editingUser: null,
    deleting: false,
    deletingUser: null,
  },
  patientData: {
    data: [],
    editing: false,
    pagination: {
      start: 0,
      limit: 10,
      page: 0,
      count: 0,
      tableParams: {
        sortBy: '',
        order: '',
        filters: {
          NID: '',
          firstname: '',
          lastname: '',
          mobileNumber: '',
          status: '',
          gender: '',
          deviceType: '',
        },
      },
    },
    editingUser: null,
    deleting: false,
    deletingUser: null,
    sending: false,
    selectedPatient: null,
  },
  contentData: {
    data: [],
    editing: false,
    pagination: {
      start: 0,
      limit: 10,
      page: 0,
      count: 0,
      tableParams: {
        sortBy: '',
        order: '',
        filters: {
          id: '',
          isPublished: '',
          // "content":'',
          // "title":'',
          // "images":'',
          // "category":'',
          // "language":'',
        },
      },
    },
    selectedContent: null,
    deleting: false,
  },
  categoryData: [],
  notificationData: {
    data: [],
    viewing: false,
    pagination: {
      start: 0,
      limit: 10,
      page: 0,
      count: 0,
      tableParams: {
        sortBy: '',
        order: '',
        filters: {
          id: '',
          title: '',
          message: '',
          status: '',
          isDeleted: '',
        },
      },
    },
    selectedNotification: null,
  },
};

export default (
  state = initialState,
  action: ManagementActionTypes,
): ManagementReducerState => {
  switch (action.type) {
    case GET_ADMIN_MANAGEMENT_DATA:
      return {
        ...state,
        adminManagementData: action.payload,
      };

    case ADD_ADMIN_TABLE_PARAMS:
      return {
        ...state,
        adminManagementData: {
          ...state.adminManagementData,
          pagination: {
            ...state.adminManagementData.pagination,
            tableParams: {
              ...state.adminManagementData.pagination.tableParams,
              ...action.payload,
            },
          },
        },
      };

    case EDIT_ADMIN_INFORMATION_START:
      if (state.adminManagementData)
        return {
          ...state,
          adminManagementData: {
            ...state.adminManagementData,
            editing: true,
            editingUser: action.payload,
          },
        };
      else return state;
    case EDIT_ADMIN_INFORMATION_FINISH:
      if (state.adminManagementData)
        return {
          ...state,
          adminManagementData: {
            ...state.adminManagementData,
            editing: false,
            editingUser: null,
          },
        };
      else return state;
    case OPEN_ADMIN_DELETE_DIALOG:
      if (state.adminManagementData) {
        return {
          ...state,
          adminManagementData: {
            ...state.adminManagementData,
            deleting: true,
            deletingUser: action.payload,
          },
        };
      } else return state;
    case CLOSE_ADMIN_DELETE_DIALOG:
      if (state.adminManagementData) {
        return {
          ...state,
          adminManagementData: {
            ...state.adminManagementData,
            deleting: false,
            deletingUser: null,
          },
        };
      } else return state;

    case GET_PATIENTS_DATA:
      return {
        ...state,
        patientData: action.payload,
      };

    case ADD_PATIENT_TABLE_PARAMS:
      return {
        ...state,
        patientData: {
          ...state.patientData,
          pagination: {
            ...state.patientData.pagination,
            tableParams: {
              ...state.patientData.pagination.tableParams,
              ...action.payload,
            },
          },
        },
      };

    case OPEN_PATIENT_NOTIFICATON_DIALOG:
      if (state.patientData)
        return {
          ...state,
          patientData: {
            ...state.patientData,
            sending: true,
            selectedPatient: action.payload,
          },
        };
      else return state;

    case CLOSE_PATIENT_NOTIFICATON_DIALOG:
      if (state.patientData)
        return {
          ...state,
          patientData: {
            ...state.patientData,
            sending: false,
            selectedPatient: null,
          },
        };
      else return state;

    case EDIT_PATIENT_INFORMATION_START:
      if (state.patientData)
        return {
          ...state,
          patientData: {
            ...state.patientData,
            editing: true,
            editingUser: action.payload,
          },
        };
      else return state;

    case EDIT_PATIENT_INFORMATION_FINISH:
      if (state.patientData)
        return {
          ...state,
          patientData: {
            ...state.patientData,
            editing: false,
            editingUser: null,
          },
        };
      else return state;
    case OPEN_PATIENT_DELETE_DIALOG:
      if (state.patientData) {
        return {
          ...state,
          patientData: {
            ...state.patientData,
            deleting: true,
            deletingUser: action.payload,
          },
        };
      } else return state;
    case CLOSE_PATIENT_DELETE_DIALOG:
      if (state.patientData) {
        return {
          ...state,
          patientData: {
            ...state.patientData,
            deleting: false,
            deletingUser: null,
          },
        };
      } else return state;

    case GET_NOTIFICATIONS_DATA:
      return {
        ...state,
        notificationData: action.payload,
      };

    case ADD_NOTIFICATION_TABLE_PARAMS:
      return {
        ...state,
        notificationData: {
          ...state.notificationData,
          pagination: {
            ...state.notificationData.pagination,
            tableParams: {
              ...state.notificationData.pagination.tableParams,
              ...action.payload,
            },
          },
        },
      };

    case VIEW_NOTIFICATION_START:
      if (state.notificationData) {
        return {
          ...state,
          notificationData: {
            ...state.notificationData,
            viewing: true,
            selectedNotification: action.payload,
          },
        };
      } else return state;

    case VIEW_NOTIFICATION_FINISH:
      if (state.notificationData)
        return {
          ...state,
          notificationData: {
            ...state.notificationData,
            viewing: false,
            selectedNotification: null,
          },
        };
      else return state;

    case GET_CONTENTS_DATA:
      return {
        ...state,
        contentData: action.payload,
      };

    case ADD_CONTENT_TABLE_PARAMS:
      return {
        ...state,
        contentData: {
          ...state.contentData,
          pagination: {
            ...state.contentData.pagination,
            tableParams: {
              ...state.contentData.pagination.tableParams,
              ...action.payload,
            },
          },
        },
      };

    case EDIT_CONTENT_INFORMATION_START:
      return {
        ...state,
        contentData: {
          ...state.contentData,
          editing: true,
          selectedContent: action.payload,
        },
      };
    case EDIT_CONTENT_INFORMATION_FINISH:
      return {
        ...state,
        contentData: {
          ...state.contentData,
          editing: false,
          selectedContent: null,
        },
      };
    case OPEN_CONTENT_DELETE_DIALOG:
      return {
        ...state,
        contentData: {
          ...state.contentData,
          deleting: true,
          selectedContent: action.payload,
        },
      };
    case CLOSE_CONTENT_DELETE_DIALOG:
      return {
        ...state,
        contentData: {
          ...state.contentData,
          deleting: false,
          selectedContent: null,
        },
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categoryData: action.payload,
      };
    default:
      return state;
  }
};
