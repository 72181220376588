import {
  ADD_CENTER_TABLE_PARAMS,
  CenterActionTypes,
  CLOSE_CENTER_DELETE_DIALOG,
  CLOSE_PHARMACIES_DELETE_DIALOG,
  CLOSE_PHC_DELETE_DIALOG,
  EDIT_CENTER_INFORMATION_FINISH,
  EDIT_CENTER_INFORMATION_START,
  EDIT_PHARMACIES_INFORMATION_FINISH,
  EDIT_PHARMACIES_INFORMATION_START,
  EDIT_PHC_INFORMATION_FINISH,
  EDIT_PHC_INFORMATION_START,
  GET_CENTER_DATA,
  GET_PHARMACIES_DATA,
  GET_PHC_DATA,
  OPEN_CENTER_DELETE_DIALOG,
  OPEN_PHARMACIES_DELETE_DIALOG,
  OPEN_PHC_DELETE_DIALOG,
} from 'types/actions/Center.action';
import {
  CenterData,
  PharmaciesCenterData,
  PHCCenterData,
} from 'types/models/Center';

type CentersReducerState = {
  centersData: CenterData;
  phcData: PHCCenterData;
  pharmaciesData: PharmaciesCenterData;
};

const initialState: CentersReducerState = {
  centersData: {
    data: [],
    pagination: {
      start: 0,
      limit: 10,
      page: 0,
      count: 0,
    },
    selectedItem: null,
    editing: false,
    deleting: false,
  },
  phcData: {
    data: [],
    pagination: {
      start: 0,
      limit: 10,
      page: 0,
      count: 0,
    },
    selectedItem: null,
    editing: false,
    deleting: false,
  },
  pharmaciesData: {
    data: [],
    pagination: {
      start: 0,
      limit: 10,
      page: 0,
      count: 0,
    },
    selectedItem: null,
    editing: false,
    deleting: false,
  },
};

export default (
  state = initialState,
  action: CenterActionTypes,
): CentersReducerState => {
  switch (action.type) {
    case GET_CENTER_DATA:
      return {
        ...state,
        centersData: {
          ...state.centersData,
          ...action.payload,
        },
      };

    case ADD_CENTER_TABLE_PARAMS:
      return {
        ...state,
        centersData: {
          ...state.centersData,
          pagination: {
            ...state.centersData.pagination,
            tableParams: {
              ...state.centersData.pagination.tableParams,
              ...action.payload,
            },
          },
        },
      };

    case EDIT_CENTER_INFORMATION_START:
      return {
        ...state,
        centersData: {
          ...state.centersData,
          editing: true,
          selectedItem: action.payload,
        },
      };
    case EDIT_CENTER_INFORMATION_FINISH:
      return {
        ...state,
        centersData: {
          ...state.centersData,
          editing: false,
          selectedItem: null,
        },
      };
    case OPEN_CENTER_DELETE_DIALOG:
      return {
        ...state,
        centersData: {
          ...state.centersData,
          deleting: true,
          selectedItem: action.payload,
        },
      };
    case CLOSE_CENTER_DELETE_DIALOG:
      return {
        ...state,
        centersData: {
          ...state.centersData,
          deleting: false,
          selectedItem: null,
        },
      };

    case GET_PHC_DATA:
      return {
        ...state,
        phcData: action.payload,
      };

    case EDIT_PHC_INFORMATION_START:
      return {
        ...state,
        phcData: {
          ...state.phcData,
          editing: true,
          selectedItem: action.payload,
        },
      };
    case EDIT_PHC_INFORMATION_FINISH:
      return {
        ...state,
        phcData: {
          ...state.phcData,
          editing: false,
          selectedItem: null,
        },
      };
    case OPEN_PHC_DELETE_DIALOG:
      return {
        ...state,
        phcData: {
          ...state.phcData,
          deleting: true,
          selectedItem: action.payload,
        },
      };
    case CLOSE_PHC_DELETE_DIALOG:
      return {
        ...state,
        phcData: {
          ...state.phcData,
          deleting: false,
          selectedItem: null,
        },
      };

    case GET_PHARMACIES_DATA:
      return {
        ...state,
        pharmaciesData: action.payload,
      };

    case EDIT_PHARMACIES_INFORMATION_START:
      return {
        ...state,
        pharmaciesData: {
          ...state.pharmaciesData,
          editing: true,
          selectedItem: action.payload,
        },
      };
    case EDIT_PHARMACIES_INFORMATION_FINISH:
      return {
        ...state,
        pharmaciesData: {
          ...state.pharmaciesData,
          editing: false,
          selectedItem: null,
        },
      };
    case OPEN_PHARMACIES_DELETE_DIALOG:
      return {
        ...state,
        pharmaciesData: {
          ...state.pharmaciesData,
          deleting: true,
          selectedItem: action.payload,
        },
      };
    case CLOSE_PHARMACIES_DELETE_DIALOG:
      return {
        ...state,
        pharmaciesData: {
          ...state.pharmaciesData,
          deleting: false,
          selectedItem: null,
        },
      };
    default:
      return state;
  }
};
