import {
  AdminModel,
  CategoryModel,
  ContentModel,
  NotificationModel,
  PatientUserModel,
} from 'types/models/Common';
import {
  AdminManagement,
  PatientManagementData,
  NotificationManagementData,
  ContentManagementData,
} from '../models/Management';

export const GET_ADMIN_MANAGEMENT_DATA = 'GET_ADMIN_MANAGEMENT_DATA';
export const ADD_ADMIN_TABLE_PARAMS = 'ADD_ADMIN_TABLE_PARAMS';
export const EDIT_ADMIN_INFORMATION_START = 'EDIT_ADMIN_INFORMATION_START';
export const EDIT_ADMIN_INFORMATION_FINISH = 'EDIT_ADMIN_INFORMATION_FINISH';
export const SET_TABLE_PAGE = 'admin/SET_TABLE_PAGE';
export const SET_ROWS_PER_PAGE = 'admin/SET_ROWS_PER_PAGE';
export const OPEN_ADMIN_DELETE_DIALOG = 'OPEN_ADMIN_DELETE_DIALOG';
export const CLOSE_ADMIN_DELETE_DIALOG = 'CLOSE_ADMIN_DELETE_DIALOG';

export interface GetManagementActions {
  type: typeof GET_ADMIN_MANAGEMENT_DATA;
  payload: AdminManagement;
}

export interface AddAdminsTableParams {
  type: typeof ADD_ADMIN_TABLE_PARAMS;
  payload: AdminManagement;
}

export interface EditAdminInformationStart {
  type: typeof EDIT_ADMIN_INFORMATION_START;
  payload: AdminModel;
}

export interface EditAdminInformationFinish {
  type: typeof EDIT_ADMIN_INFORMATION_FINISH;
}

export type SetTablePage = {
  type: typeof SET_TABLE_PAGE;
  page: number;
};

export type SetRowsPerPage = {
  type: typeof SET_ROWS_PER_PAGE;
  rowsPerPage: number;
};

export type OpenAdminDeleteDialog = {
  type: typeof OPEN_ADMIN_DELETE_DIALOG;
  payload: AdminModel;
};

export type CloseAdminDeleteDialog = {
  type: typeof CLOSE_ADMIN_DELETE_DIALOG;
};

/**
 * Patient Management Types
 */

export const GET_PATIENTS_DATA = 'GET_PATIENTS_DATA';
export const ADD_PATIENT_TABLE_PARAMS = 'ADD_PATIENT_TABLE_PARAMS';

export const OPEN_PATIENT_NOTIFICATON_DIALOG =
  'OPEN_PATIENT_NOTIFICATON_DIALOG';
export const CLOSE_PATIENT_NOTIFICATON_DIALOG =
  'CLOSE_PATIENT_NOTIFICATON_DIALOG';
export const EDIT_PATIENT_INFORMATION_START = 'EDIT_PATIENT_INFORMATION_START';
export const EDIT_PATIENT_INFORMATION_FINISH =
  'EDIT_PATIENT_INFORMATION_FINISH';
export const OPEN_PATIENT_DELETE_DIALOG = 'OPEN_PATIENT_DELETE_DIALOG';
export const CLOSE_PATIENT_DELETE_DIALOG = 'CLOSE_PATIENT_DELETE_DIALOG';

export interface GetPatientsData {
  type: typeof GET_PATIENTS_DATA;
  payload: PatientManagementData;
}

export interface GetPatientsTableParams {
  type: typeof ADD_PATIENT_TABLE_PARAMS;
  payload: PatientManagementData;
}

export interface OpenPatientNotificationDialog {
  type: typeof OPEN_PATIENT_NOTIFICATON_DIALOG;
  payload: PatientUserModel;
}

export interface ClosePatientNotificationDialog {
  type: typeof CLOSE_PATIENT_NOTIFICATON_DIALOG;
}

export interface EditPatientInformationStart {
  type: typeof EDIT_PATIENT_INFORMATION_START;
  payload: PatientUserModel;
}

export interface EditPatientInformationFinish {
  type: typeof EDIT_PATIENT_INFORMATION_FINISH;
}

export type OpenPatientDeleteDialog = {
  type: typeof OPEN_PATIENT_DELETE_DIALOG;
  payload: PatientUserModel;
};

export type ClosePatientDeleteDialog = {
  type: typeof CLOSE_PATIENT_DELETE_DIALOG;
};

/**
 * Notification Management Types
 */

export const GET_NOTIFICATIONS_DATA = 'GET_NOTIFICATIONS_DATA';
export const ADD_NOTIFICATION_TABLE_PARAMS = 'ADD_NOTIFICATION_TABLE_PARAMS';
export const VIEW_NOTIFICATION_START = 'VIEW_NOTIFICATION_START';
export const VIEW_NOTIFICATION_FINISH = 'VIEW_NOTIFICATION_FINISH';

export interface GetNotificationsData {
  type: typeof GET_NOTIFICATIONS_DATA;
  payload: NotificationManagementData;
}

export interface addNotificationTableParams {
  type: typeof ADD_NOTIFICATION_TABLE_PARAMS;
  payload: NotificationManagementData;
}

export interface ViewNotificationStart {
  type: typeof VIEW_NOTIFICATION_START;
  payload: NotificationModel;
}

export interface ViewNotificationFinish {
  type: typeof VIEW_NOTIFICATION_FINISH;
}

/**
 * Content Management Types
 */

export const GET_CONTENTS_DATA = 'GET_CONTENTS_DATA';
export const ADD_CONTENT_TABLE_PARAMS = 'ADD_CONTENT_TABLE_PARAMS';
export const EDIT_CONTENT_INFORMATION_START = 'EDIT_CONTENT_INFORMATION_START';
export const EDIT_CONTENT_INFORMATION_FINISH =
  'EDIT_CONTENT_INFORMATION_FINISH';
export const SET_CONTENT_TABLE_PAGE = 'SET_CONTENT_TABLE_PAGE';
export const SET_CONTENT_ROWS_PER_PAGE = 'SET_CONTENT_ROWS_PER_PAGE';
export const OPEN_CONTENT_DELETE_DIALOG = 'OPEN_CONTENT_DELETE_DIALOG';
export const CLOSE_CONTENT_DELETE_DIALOG = 'CLOSE_CONTENT_DELETE_DIALOG';
export const GET_CATEGORIES = 'GET_CATEGORIES';

export interface GetCategories {
  type: typeof GET_CATEGORIES;
  payload: CategoryModel[];
}

export interface GetContentsData {
  type: typeof GET_CONTENTS_DATA;
  payload: ContentManagementData;
}

export interface AddContentTableParams {
  type: typeof ADD_CONTENT_TABLE_PARAMS;
  payload: ContentManagementData;
}

export interface EditContentInformationStart {
  type: typeof EDIT_CONTENT_INFORMATION_START;
  payload: ContentModel;
}

export interface EditContentInformationFinish {
  type: typeof EDIT_CONTENT_INFORMATION_FINISH;
}

export type SetContentTablePage = {
  type: typeof SET_CONTENT_TABLE_PAGE;
  page: number;
};

export type SetContentRowsPerPage = {
  type: typeof SET_CONTENT_ROWS_PER_PAGE;
  rowsPerPage: number;
};

export type OpenContentDeleteDialog = {
  type: typeof OPEN_CONTENT_DELETE_DIALOG;
  payload: ContentModel;
};

export type CloseContentDeleteDialog = {
  type: typeof CLOSE_CONTENT_DELETE_DIALOG;
};

export type ManagementActionTypes =
  | GetManagementActions
  | AddAdminsTableParams
  | EditAdminInformationStart
  | EditAdminInformationFinish
  | SetTablePage
  | SetRowsPerPage
  | OpenAdminDeleteDialog
  | CloseAdminDeleteDialog
  | GetPatientsData
  | GetPatientsTableParams
  | OpenPatientNotificationDialog
  | ClosePatientNotificationDialog
  | EditPatientInformationStart
  | EditPatientInformationFinish
  | OpenPatientDeleteDialog
  | ClosePatientDeleteDialog
  | GetContentsData
  | AddContentTableParams
  | EditContentInformationStart
  | EditContentInformationFinish
  | OpenContentDeleteDialog
  | CloseContentDeleteDialog
  | GetCategories
  | GetNotificationsData
  | addNotificationTableParams
  | ViewNotificationStart
  | ViewNotificationFinish;
